import Vue from 'vue'

Vue.mixin({
  methods: {
    getMonthDateRange: (array = false) => {
      const date = new Date(),
        y = date.getFullYear(),
        m = date.getMonth()

      const firstDay = new Date(y, m, 1)
      const lastDay = new Date(y, m + 1, 0)

      let firstDate =
        firstDay.getFullYear() +
        '-' +
        (lastDay.getMonth() + 1 < 10
          ? '0' + (lastDay.getMonth() + 1)
          : lastDay.getMonth()) +
        '-' +
        (firstDay.getDate() < 10
          ? '0' + firstDay.getDate()
          : firstDay.getDate())

      let lastDate =
        lastDay.getFullYear() +
        '-' +
        (lastDay.getMonth() + 1 < 10
          ? '0' + (lastDay.getMonth() + 1)
          : lastDay.getMonth()) +
        '-' +
        (lastDay.getDate() < 10 ? '0' + lastDay.getDate() : lastDay.getDate())

      if (array) return [firstDate, lastDate]

      return { start: firstDate, end: lastDate }
    },

    getYearDateRange: () => {
      const date = new Date(),
        year = date.getFullYear()

      return { start: year + '-01-01', end: year + '-12-31' }
    },

    date2sql: (date) => {
      return Vue.moment(date).format('YYYY-MM-DD')
    },

    sql2date: (date) => {
      return Vue.moment(date).format('DD/MM/YYYY')
    },

    getSalesmanStatus: () => {
      const session = Vue.$cookies.get('session')
      return session != null && session.isSalesman == 1
    },

    removeCommas: (string) => {
      return string.replace(/,/g, '')
    },
  },
})
