import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Dashboard',
    component: () => import('@/views/Dashboard.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/Login.vue'),
  },
  {
    path: '/sales',
    name: 'Sales',
    component: () => import('@/views/Sales.vue'),
    meta: { requiresAuth: true, salesman: true },
  },
  {
    path: '/sales/reached-due',
    name: 'SalesReachedDue',
    component: () => import('@/views/Sales/InvoiceReachedDue'),
    meta: {
      requiresAuth: true,
      usePrevious: true,
      salesman: true,
      salesmanBack: true,
    },
  },
  {
    path: '/sales/invoices',
    name: 'SalesInvoices',
    component: () => import('@/views/Sales/Invoices.vue'),
    meta: { requiresAuth: true, usePrevious: true },
  },
  {
    path: '/sales/invoices/:branchCode/:startDate/:endDate',
    component: () => import('@/views/Sales/InvoiceDetails.vue'),
    meta: { requiresAuth: true, usePrevious: true },
  },
  {
    path: '/sales/invoices/:transNo',
    component: () => import('@/views/Sales/Invoice.vue'),
    meta: { usePrevious: true, salesman: true, salesmanBack: true },
  },
  {
    path: '/sales/payments',
    name: 'SalesPayments',
    component: () => import('@/views/Sales/Payments.vue'),
    meta: { requiresAuth: true, usePrevious: true },
  },
  {
    path: '/sales/payments/:branchCode/:startDate/:endDate',
    component: () => import('@/views/Sales/PaymentDetails.vue'),
    meta: { requiresAuth: true, usePrevious: true },
  },
  {
    path: '/sales/returns',
    name: 'SalesReturns',
    component: () => import('@/views/Sales/Returns.vue'),
    meta: { requiresAuth: true, usePrevious: true },
  },
  {
    path: '/sales/returns/:branchCode/:startDate/:endDate',
    component: () => import('@/views/Sales/ReturnDetails.vue'),
    meta: { requiresAuth: true, usePrevious: true },
  },
  {
    path: '/sales/receivables',
    name: 'SalesReceivables',
    component: () => import('@/views/Sales/Receivables.vue'),
    meta: { requiresAuth: true, usePrevious: true },
  },
  {
    path: '/sales/receivables/:branchCode/:startDate/:endDate',
    component: () => import('@/views/Sales/ReceivableDetails.vue'),
    meta: { requiresAuth: true, usePrevious: true },
  },
  {
    path: '/sales/salesmans',
    name: 'SalesSalesmans',
    component: () => import('@/views/Sales/Salesmans.vue'),
    meta: {
      requiresAuth: true,
      usePrevious: true,
      salesman: true,
      salesmanBack: true,
    },
  },
  {
    path: '/sales/salesmans/:salesmanCode/:startDate/:endDate',
    component: () => import('@/views/Sales/SalesmanDetails.vue'),
    meta: {
      requiresAuth: true,
      usePrevious: true,
      salesman: true,
      salesmanBack: true,
    },
  },
  {
    path: '/purchasing',
    name: 'Purchasing',
    component: () => import('@/views/Purchasing.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/purchasing/invoices',
    component: () => import('@/views/Purchasing/Invoices.vue'),
    meta: { requiresAuth: true, usePrevious: true },
  },
  {
    path: '/purchasing/invoices/:supplierID/:startDate/:endDate',
    component: () => import('@/views/Purchasing/InvoiceDetails.vue'),
    meta: { requiresAuth: true, usePrevious: true },
  },
  {
    path: '/purchasing/payments',
    component: () => import('@/views/Purchasing/Payments.vue'),
    meta: { requiresAuth: true, usePrevious: true },
  },
  {
    path: '/purchasing/payments/:supplierID/:startDate/:endDate',
    component: () => import('@/views/Purchasing/PaymentDetails.vue'),
    meta: { requiresAuth: true, usePrevious: true },
  },
  {
    path: '/purchasing/returns',
    component: () => import('@/views/Purchasing/Returns.vue'),
    meta: { requiresAuth: true, usePrevious: true },
  },
  {
    path: '/purchasing/returns/:supplierID/:startDate/:endDate',
    component: () => import('@/views/Purchasing/ReturnDetails.vue'),
    meta: { requiresAuth: true, usePrevious: true },
  },
  {
    path: '/purchasing/debts',
    component: () => import('@/views/Purchasing/Debts.vue'),
    meta: { requiresAuth: true, usePrevious: true },
  },
  {
    path: '/purchasing/debts/:supplierID/:startDate/:endDate',
    component: () => import('@/views/Purchasing/DebtDetails.vue'),
    meta: { requiresAuth: true, usePrevious: true },
  },
  {
    path: '/inventory',
    name: 'Inventory',
    component: () => import('@/views/Inventory.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/inventory/items',
    component: () => import('@/views/Inventory/Items.vue'),
    meta: { requiresAuth: true, usePrevious: true, salesman: true },
  },
  {
    path: '/inventory/items/sales',
    component: () => import('@/views/Inventory/ItemSalesReport'),
  },
  {
    path: '/inventory/items/:stock_id',
    component: () => import('@/views/Inventory/ItemDetails.vue'),
    meta: {
      requiresAuth: true,
      usePrevious: true,
      salesman: true,
      salesmanBack: true,
    },
  },
  {
    path: '/bank/:id/balance/details',
    component: () => import('@/views/BankBalanceDetails'),
    meta: { requiresAuth: true, usePrevious: true },
  },
  {
    path: '/accounting',
    component: () => import('@/views/Accounting.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/accounting/expenses',
    component: () => import('@/views/Accounting/Expenses.vue'),
    meta: { requiresAuth: true, usePrevious: true },
  },
  {
    path: '/accounting/expenses/create',
    component: () => import('@/views/Accounting/ExpensesCreate.vue'),
    meta: { requiresAuth: true, usePrevious: true },
  },
  {
    path: '/accounting/expenses/:transNo',
    component: () => import('@/views/Accounting/Expense.vue'),
    meta: { requiresAuth: true, usePrevious: true },
  },
  {
    path: '/accounting/expenses/:transNo/edit',
    component: () => import('@/views/Accounting/ExpensesEdit.vue'),
    meta: { requiresAuth: true, usePrevious: true },
  },
  {
    path: '/accounting/bank-transfers',
    component: () => import('@/views/Accounting/BankTransfers/index.vue'),
    meta: { requiresAuth: true, usePrevious: true },
  },
  {
    path: '/accounting/bank-transfers/create',
    component: () => import('@/views/Accounting/BankTransfers/create.vue'),
    meta: { requiresAuth: true, usePrevious: true },
  },
  {
    path: '/accounting/bank-transfers/:transNo/edit',
    component: () => import('@/views/Accounting/BankTransfers/edit.vue'),
    meta: { requiresAuth: true, usePrevious: true },
  },
  {
    path: '/accounting/bank-transfers/:transNo',
    component: () => import('@/views/Accounting/BankTransfers/_transNo.vue'),
    meta: { requiresAuth: true, usePrevious: true },
  },

  {
    path: '/accounting/deposits',
    component: () => import('@/views/Accounting/deposits/index.vue'),
    meta: { requiresAuth: true, usePrevious: true },
  },
  {
    path: '/accounting/deposits/create',
    component: () => import('@/views/Accounting/deposits/create.vue'),
    meta: { requiresAuth: true, usePrevious: true },
  },
  {
    path: '/accounting/deposits/:transNo',
    component: () => import('@/views/Accounting/deposits/_transNo.vue'),
    meta: { requiresAuth: true, usePrevious: true },
  },
  {
    path: '/accounting/deposits/:transNo/edit',
    component: () => import('@/views/Accounting/deposits/edit.vue'),
    meta: { requiresAuth: true, usePrevious: true },
  },

  /**
   * Bank Journal
   */
  {
    path: '/accounting/banks/journals',
    component: () => import('@/views/Accounting/banks/journals/index.vue'),
    meta: { requireAuth: true },
  },
  {
    path: '/accounting/banks/journals/:type/:transNo',
    component: () => import('@/views/Accounting/banks/journals/show.vue'),
    meta: { requireAuth: true, usePrevious: true },
  },
]

const router = new VueRouter({
  routes,
})

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth))
    if (Vue.$cookies.get('session') === null) return next('/login')

  const session = Vue.$cookies.get('session')

  if (session != null) {
    Vue.$cookies.set('session', session)

    if (
      session.isSalesman === 1 &&
      !to.matched.some((record) => record.meta.salesman)
    )
      return router.push('/inventory/items')
  }

  next()
})

export default router
