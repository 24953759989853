<template>
  <v-app>
    <v-app-bar app v-if="!isLogin" color="#121212" height="100" flat>
      <v-spacer></v-spacer>

      <v-toolbar-title class="font-weight-black">
        {{ appName }}
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <v-btn
        dark
        fab
        small
        color="red"
        style="position: absolute; right: 25px"
        @click="logout"
      >
        <v-icon dark>mdi-power</v-icon>
      </v-btn>

      <v-btn
        v-if="usePrevious && (!isSalesman || this.$route.meta.salesmanBack)"
        dark
        fab
        small
        color="light blue"
        style="position: absolute; left: 25px"
        @click="$router.back()"
      >
        <v-icon dark>mdi-arrow-left</v-icon>
      </v-btn>
    </v-app-bar>

    <v-main>
      <v-container fluid fill-height style="display: flex">
        <router-view :key="$route.fullPath" style="flex: 1"></router-view>
      </v-container>
    </v-main>

    <v-bottom-navigation
      app
      v-if="!isLogin && !hideNavigation"
      color="light-blue lighten-3"
    >
      <template v-for="menu in navigationMenus">
        <v-btn
          v-if="!isSalesman || (isSalesman && menu.forSalesman)"
          :key="menu.title"
          :to="menu.path"
        >
          <span v-if="menu.title" v-text="menu.title" class="text-sm"></span>

          <v-badge v-if="menu.notification" color="red">
            <v-icon v-text="menu.icon" :small="!!menu.title"></v-icon>
          </v-badge>

          <v-icon v-else v-text="menu.icon" :small="!!menu.title"></v-icon>
        </v-btn>
      </template>
    </v-bottom-navigation>

    <v-snackbar v-model="updateAvailable" color="primary" timeout="-1">
      New version is available!

      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" class="ml-auto" text @click="refreshApp"
          >UPDATE
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>
export default {
  name: 'App',

  data() {
    return {
      isSalesman: this.getSalesmanStatus(),
      navigationMenus: [
        {
          path: '/sales',
          title: 'Sales',
          icon: 'mdi-chart-line',
          forSalesman: true,
        },
        {
          path: '/inventory',
          title: 'Inventory',
          icon: 'mdi-package-variant',
          forSalesman: true,
        },
        {
          path: '/',
          icon: 'mdi-home',
        },
        {
          path: '/purchasing',
          title: 'Purchase',
          icon: 'mdi-cart',
        },
        {
          path: '/accounting/banks/journals',
          title: 'Finance',
          icon: 'mdi-scale-balance',
        },
      ],
      hideNavigation: false,
      refreshing: false,
      registration: null,
      updateAvailable: false,
    }
  },

  computed: {
    appName() {
      return process.env.VUE_APP_NAME
    },

    isLogin() {
      return this.$route.name === 'Login'
    },

    usePrevious() {
      return this.$route.meta.usePrevious
    },
  },

  watch: {
    $route() {
      this.isSalesman = this.getSalesmanStatus()
    },
  },

  created() {
    document.addEventListener('swUpdated', this.showRefreshUI, { once: true })

    if (navigator.serviceWorker) {
      navigator.serviceWorker.addEventListener('controllerchange', () => {
        if (this.refreshing) return

        this.refreshing = true
        window.location.reload()
      })
    }

    window.addEventListener('focusin', (event) => {
      if (event.target.tagName.toUpperCase() === 'INPUT') {
        this.hideNavigation = true
      }
    })

    window.addEventListener('focusout', (event) => {
      if (event.target.tagName.toUpperCase() === 'INPUT') {
        this.hideNavigation = false
      }
    })
  },

  mounted() {
    this.getSalesReachedDueDate()
  },

  methods: {
    logout() {
      this.$cookies.remove('session')
      this.$router.push('/login')
    },

    showRefreshUI(e) {
      this.registration = e.detail
      this.updateAvailable = true
    },

    refreshApp() {
      this.updateAvailable = false

      if (!this.registration || !this.registration.waiting) {
        return
      }

      this.registration.waiting.postMessage('skipWaiting')
    },

    getSalesReachedDueDate() {
      this.$http.get('/../notifications/get').then(({ data }) => {
        if (data.count > 0) {
          this.navigationMenus[1].notification = true
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.v-main {
  .container {
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-image: url('/img/map.png');
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
  }
}

.container.fill-height {
  align-items: inherit;
}

.text-sm {
  font-size: xx-small;
}

.v-bottom-navigation {
  display: flex !important;

  .v-btn {
    flex-grow: 1 !important;
    flex-basis: auto !important;
    min-width: unset !important;
    height: 100% !important;
  }
}
</style>

<style lang="scss">
.v-data-table > .v-data-table__wrapper .v-data-table__mobile-row {
  min-height: 36px !important;

  .v-btn {
    margin-bottom: 10px !important;
  }
}
</style>
