import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import vuetify from './plugins/vuetify';
import axios from 'axios';
import VueCookies from 'vue-cookies';
import moment from 'vue-moment';
import './helper';

import VCalendar from 'v-calendar';

// axios.defaults.baseURL = 'http://localhost/untitled/api/'
axios.defaults.baseURL = 'https://industry.infineticglobal.com/api/';

Vue.config.productionTip = false;
Vue.prototype.$http = axios;

Vue.use(VueCookies);
Vue.use(moment);

Vue.use(VCalendar, {
  componentPrefix: 'vc'
});

new Vue({
  router,
  vuetify,
  render: (h) => h(App)
}).$mount('#app');
